import { initFirebase } from "./fb.service";
import { initAuthService } from "./auth.service";
import { initBifrost } from "./bifrost.service";
import { initGlobalStateService } from "./global-state.service";
import { initializeI18n } from "@ollie-sports/i18n";

let hasInitialized = false;
export async function initApp() {
  if (hasInitialized) {
    return;
  }

  initializeI18n({
    locale: navigator.language || "en-US"
  });

  hasInitialized = true;

  initFirebase();
  await Promise.all([initGlobalStateService(), initAuthService()]);
  await initBifrost();
}
