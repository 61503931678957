import { modifyGlobalState } from "./global-state.service";
import { getFbApp } from "./fb.service";
import { clear } from "idb-keyval";
import localforage from "localforage";
import { SingleChannelEventEmitter } from "../utils/SingleChannelEventEmitter";
import firebase from "firebase";

const authChangeEE = new SingleChannelEventEmitter<firebase.User | null>();

export function initAuthService() {
  return new Promise<void>(resolve => {
    getFbApp()
      .auth()
      .onAuthStateChanged(async res => {
        if (res) {
          await modifyGlobalState("User logs in", s => {
            s.auth = {
              uid: res.uid,
              isLoggedIn: true,
              email: res.email || ""
            };
          });
        } else {
          await modifyGlobalState("User no longer logged in", s => {
            s.auth = {
              uid: "",
              isLoggedIn: false,
              email: ""
            };
          });
        }
        authChangeEE.emit(res);
        resolve();
      });
  });
}

export async function login(p: { email: string; password: string }) {
  const loginProm = authChangeEE.onceChange();
  const resp = await getFbApp().auth().signInWithEmailAndPassword(p.email, p.password);

  await loginProm;
  return resp;
}

export async function logout() {
  const logoutProm = authChangeEE.onceChange();
  modifyGlobalState("User no longer logged in", s => {
    s.auth.isLoggedIn = false;
    s.auth.email = "";
    s.auth.uid = "";
  });
  await getFbApp().auth().signOut();

  await logoutProm;

  localStorage.clear();
  await localforage.clear();
  await clear();
  sessionStorage.clear();
}
