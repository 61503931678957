import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";

import { config } from "../config";
import { initOllieCore } from "@ollie-sports/core";
import { getOlliePipe } from "./olliePipe.service";

let fbApp: firebase.app.App;

export function initFirebase() {
  if (!firebase.apps.length) {
    fbApp = firebase.initializeApp(config.firebaseWebConfig);

    initOllieCore({
      firebaseApp: fbApp,
      firebaseModule: firebase,
      olliePipe: getOlliePipe(),
      httpWebAppApiRoot: config.appApiRoot
    });
  }
}

export function getFbApp(): firebase.app.App {
  if (!fbApp) {
    throw new Error("Must init firebase app");
  }

  return fbApp;
}
