import { produce } from "immer";
import createStore from "zustand";
import localforage from "localforage";

const GLOBAL_STATE_KEY = "global-state-cache-key";

type GlobalState = {
  auth: {
    isLoggedIn: boolean;
    email: string;
    uid: string;
  };
  ui: {
    sideDrawerOpen: boolean;
  };
};

const useGlobalStateStore = createStore(() => {
  const initState: GlobalState = {
    auth: {
      isLoggedIn: false,
      email: "",
      uid: ""
    },
    ui: {
      sideDrawerOpen: false
    }
  };

  return initState;
});

export async function initGlobalStateService() {
  // const savedState = await localforage.getItem<GlobalState | null>(GLOBAL_STATE_KEY);
  // if (savedState) {
  //   useGlobalStateStore.setState(savedState);
  // }
}

export function getGlobalState() {
  return useGlobalStateStore.getState();
}

export function useGlobalState() {
  return useGlobalStateStore();
}

export function modifyGlobalState(description: string, updateFn: (a: GlobalState) => void): Promise<void> {
  return new Promise(resolve => {
    setTimeout(async () => {
      useGlobalStateStore.setState(produce(useGlobalStateStore.getState(), updateFn));

      await localforage.setItem(GLOBAL_STATE_KEY, useGlobalStateStore.getState());

      resolve();
    }, 0);
  });
}
