import axios from "axios";
import { api } from "@ollie-sports/core";
import React from "react";
import _ from "lodash";
import { BifrostInstance, createBifrost, getQueryClient } from "@ollie-sports/react-bifrost";
import { config } from "../config";
import { getFbApp } from "./fb.service";
import { QueryClient, dehydrate, hydrate } from "react-query";
import localforage from "localforage";
import { getOlliePipe } from "./olliePipe.service";
import { getCurrentLocale } from "@ollie-sports/i18n";

const QUERY_CACHE_KEY = "BIFROST CACHE";
export type BifrostType = BifrostInstance<typeof api>;
let bifrost: BifrostType;

export async function initBifrost(): Promise<void> {
  if (bifrost) {
    return;
  }

  const queryClient = getQueryClient() as QueryClient;
  const dehydratedCache = await localforage.getItem(QUERY_CACHE_KEY);
  if (dehydratedCache) {
    try {
      const cache = JSON.parse(dehydratedCache as string) as { queries: { queryKey: string; state: { data: unknown } }[] };

      hydrate(queryClient, cache);
    } catch (e) {
      getOlliePipe().emitEvent({ type: "error-hydrating-bifrost", payload: e });
      await localforage.removeItem(QUERY_CACHE_KEY);
    }
  }

  function saveCacheToDiskImmediate() {
    localforage.setItem(QUERY_CACHE_KEY, JSON.stringify(dehydrate(getQueryClient()))).catch(e => {
      console.error("Unable to save bifrost cache", e);
    });
  }

  const saveCacheToDiskDebounced = _.debounce(saveCacheToDiskImmediate, 5000, { leading: false, trailing: true });

  queryClient.setDefaultOptions({
    queries: {
      onSuccess: () => {
        saveCacheToDiskDebounced();
      }
    }
  });

  bifrost = createBifrost({
    fns: api,
    reactModule: React,
    httpProcessor: async ({ payload, fnName }) => {
      let url = `${config.appApiRoot}/api-bifrost/${fnName}`;
      payload = omitByRecursively(payload, _.isUndefined);
      try {
        let token = (await getAuthToken()) ?? "none";
        let r1 = await axios.post(url, payload, {
          headers: {
            "acccept-language": getCurrentLocale(),
            Authorization: token
          }
        });

        return r1.data;
      } catch (err) {
        const e = err ? (err as any) : {};
        if (e.status !== 200) {
          throw errorFromObj({
            message: `Server request to ${fnName} failed`,
            url,
            status: e.status,
            returnData: e.data,
            fnName,
            payload,
            stack: new Error().stack
          });
        } else if (!e.response) {
          throw errorFromObj({
            message: `Not connected to the internet for request to ${fnName}`,
            url,
            fnName,
            payload,
            stack: new Error().stack
          });
        } else {
          throw e;
        }
      }
    },
    logger: p => {
      if (p.error) {
        console.error(`Error running ${p.fnName}`);
        console.error(p.error);
      }
    }
  });
}

export function getBifrost() {
  return bifrost;
}

export async function getAuthToken() {
  let currentUser = getFbApp().auth().currentUser;

  if (!currentUser) {
    return null;
  }

  return await currentUser.getIdToken();
}

function omitByRecursively<T extends Object>(value: T, iteratee: Function): T {
  var cb = (v: any) => omitByRecursively(v, iteratee);
  return (
    _.isObject(value)
      ? _.isArray(value)
        ? _.map(value, cb)
        : _(value)
            .omitBy(iteratee as any)
            .mapValues(cb)
            .value()
      : value
  ) as T;
}

function errorFromObj<T extends {}>(obj: { message: string; stack: any } & T) {
  const { message, stack, ...rest } = obj;
  const error = new Error(message);
  error.stack = stack;

  Object.keys(rest).forEach(key => {
    (error as any)[key] = (rest as any)[key];
  });

  return error;
}
