import { Dialog } from "@material-ui/core";
import { ReactNode } from "react";
import { createZustandStore } from "../utils/createZustandStore";

type Modal = {
  id: string;
  isOpen: boolean;
  content: ReactNode;
  destroy: () => Promise<void>;
  onDismiss?: () => void;
  onClose?: () => {};
};
const rootDialogStore = createZustandStore<{
  modals: Modal[];
}>({ modals: [] });

const ANIMATION_DURATION = 500;

export function openRootDialog(p: { content: ReactNode; uniquenessId: string; onClose: () => any }): Modal {
  async function destroy() {
    rootDialogStore.modifyImmutably(a => {
      const index = a.modals.findIndex(b => b.id === p.uniquenessId);

      if (index !== -1) {
        a.modals[index]!.isOpen = false;
      }
    });

    await new Promise(res => setTimeout(res, ANIMATION_DURATION));

    rootDialogStore.modifyImmutably(a => {
      const index = a.modals.findIndex(b => b.id === p.uniquenessId);
      if (index !== -1) {
        a.modals.splice(index, 1);
      }
    });
  }

  rootDialogStore.modifyImmutably(a => {
    a.modals.push({ id: p.uniquenessId, content: p.content, isOpen: true, destroy, onClose: p.onClose });
  });

  return rootDialogStore.get().modals.find(a => a.id === p.uniquenessId)!;
}

export function RootDialogProvider(p: { children: ReactNode }) {
  const modals = rootDialogStore.useStore(a => a.modals);

  return (
    <>
      {modals.map(a => (
        <Dialog
          open={a.isOpen}
          onClose={() => {
            a.onClose?.();
            a.destroy();
          }}
          key={a.id}
        >
          {a.content}
        </Dialog>
      ))}
      {p.children}
    </>
  );
}
